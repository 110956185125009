import React, { useState, useEffect } from "react"
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { fadeIn, backgroundToBottom } from "./functions"
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

export default function GalleryImages({
    doc
}) {

    const controls = useAnimation();
    const { ref, inView } = useInView({});

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
        if (!inView) {
            controls.start('hidden');
        }
    }, [controls, inView]);

    const [selectedIdImg, setSelectedIdImg] = useState(null)

    return (
        <>
            <motion.div
                key={doc.id}
                ref={ref}
                className="img width19"
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                layoutId={doc.id}
                onClick={() => setSelectedIdImg(doc.id)}
            >
                <motion.div
                    initial="hidden"
                    animate={controls}
                    variants={fadeIn}
                    transition={{ delay: .8 }}
                >
                    <GatsbyImage image={getImage(doc.img)} alt={doc.altImg} />
                </motion.div>
                <motion.div
                    className="first_background"
                    initial="hidden"
                    animate={controls}
                    variants={backgroundToBottom}
                    transition={{ delay: .6, ease: "easeInOut", duration: .8 }}
                />
            </motion.div>
            <AnimatePresence>
                {selectedIdImg === doc.id &&
                    (
                        <motion.div
                            id="modal_img"
                            layoutId={selectedIdImg}
                            onClick={() => setSelectedIdImg(null)}
                        >
                            <button
                                id="return_button"
                                onClick={() => setSelectedIdImg(null)}
                                onKeyDown={() => setSelectedIdImg(null)}
                                tabIndex="0"
                            >
                                <FontAwesomeIcon icon={faArrowLeftLong} />
                            </button>
                            <div className="image">
                                <GatsbyImage
                                    image={getImage(doc.img)}
                                    alt={doc.altImg}
                                />
                            </div>
                        </motion.div>
                    )
                }
            </AnimatePresence>
        </>
    )
}