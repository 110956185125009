import React, { useState } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import scrollTo from 'gatsby-plugin-smoothscroll'
import links from "../data/links.json"
import './nav.scss'

export default function Nav() {
    const [showMenu, setShowMenu] = useState('no_active')
    const [selectedId, setSelectedId] = useState(null)
    const key = "navbar"

    function handleClick() {
        if (showMenu === 'no_active') {
            setShowMenu('active')
            setSelectedId(key)
        } else {
            setShowMenu('no_active')
            setSelectedId(null)
        }
    }

    const variants = {
        visible: i => ({
            scale: 1,
            opacity: 1,
            transition: {
                delay: i * 0.2,
            },
        }),
        hidden: { scale: 0, opacity: 0 },
    }

    return (
        <>
            <motion.div
                id="nav_faux_button"
                layoutId={key}
            >
            </motion.div>
            <button
                id="nav_button"
                className={showMenu}
                onClick={handleClick}
                onKeyDown={handleClick}
                tabIndex="0"
            >
                <div className="nav_spans">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
            <AnimatePresence>
                {selectedId && (
                    <motion.nav id="navbar"
                        layoutId={selectedId}
                        onClick={handleClick}>
                        <div className="div_navbar">
                            <div className="liens_navbar links_navbar width_50">
                                {links.data.map((data, i) => (
                                    <motion.ul
                                        key={data.id}
                                        className="lien_nav"
                                        initial="hidden"
                                        animate="visible"
                                        custom={i}
                                        variants={variants}
                                    >
                                        <li>
                                            <button className="link_animate" onClick={() => scrollTo(data.url)}>
                                                <span className="texte_lien_span">
                                                    {data.text}
                                                </span>
                                            </button>
                                        </li>
                                    </motion.ul>
                                ))}
                            </div>
                            <div className="width_25" />
                        </div>
                    </motion.nav>
                )}
            </AnimatePresence>
        </>
    )
}