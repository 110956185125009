import React from "react"
import Nav from './nav'
import Footer from "./footer"

const Layout = ({ children }) => {

  return (
    <div className="global-wrapper">
        <Nav />
        <main>{children}</main>
        <Footer />
    </div>
  )
}

export default Layout