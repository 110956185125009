export const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
}

export const toTop = {
    hidden: { y: 50 },
    visible: { y: 0 }
}

export const toBottom = {
    hidden: { y: 0 },
    visible: { y: 50 }
}

export const backgroundToTop = {
    hidden: {},
    visible: { y: [700, 0, 0, -700] }
}

export const backgroundToBottom = {
    hidden: {},
    visible: { y: [-700, 0, 0, 700] }
}