import React from 'react'
import './footer.scss'
import { Link } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'
import links from "../data/links.json"

export default function Footer() {
    return (
        <div className="footer">
            <div className="blocks container">
                <div className="block width50">
                    <h2>Liens rapides</h2>
                    {links.data.map((data) => (
                        <button key={data.id} className="link_animate" onClick={() => scrollTo(data.url)}>
                            <span className="texte_lien_span">
                                {data.text}
                            </span>
                        </button>
                    ))}
                </div>
                <div className="block width50">
                    <h2>Contact</h2>
                    <a href="https://goo.gl/maps/xiJVuojwNb3RYUQ48" target="_blank" rel="noreferrer">
                        <span>
                            681 route du Reban<br />
                            73270 Villard-sur-Doron
                        </span>
                    </a>
                    <a href="tel:+33676293739" className="tel link_animate">
                        <span>
                            06 76 29 37 39
                        </span>
                    </a>
                    <a href="mailto:verslesplans@outlook.fr" className="email link_animate">
                        <span>
                            verslesplans@outlook.fr
                        </span>
                    </a>
                </div>
            </div>

            <div className="copyright">
                <p>2022 © Vers les Plans - <Link to="/mentions-legales" className="link_animate"><span>Mentions Légales</span></Link></p>
            </div>
        </div>
    )
}