import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBacon, faBath, faBed, faCircle, faCircleDot, faDisplay, faHotTubPerson, faKitchenSet, faPersonSkiing, faUtensils, faWifi } from '@fortawesome/free-solid-svg-icons';
import { fadeIn, backgroundToTop, backgroundToBottom } from './functions';
import './equipements.scss'

export default function Equipements() {

    const controls = useAnimation();
    const { ref, inView } = useInView({});

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
        if (!inView) {
            controls.start('hidden');
        }
    }, [controls, inView]);

    return (
        <section id="equipements" ref={ref}>

            <div className="container blocks">
                <div className="equipement width40">
                    <motion.p
                        className="before_title"
                        initial="hidden"
                        animate={controls}
                        variants={fadeIn}
                        transition={{ delay: .3, ease: "easeOut", duration: .5 }}
                    >
                        Confort
                    </motion.p>
                    <motion.h1
                        initial="hidden"
                        animate={controls}
                        variants={fadeIn}
                        transition={{ delay: .6, ease: "easeOut", duration: .5 }}
                    >
                        Équipements
                    </motion.h1>
                    <ul className="coordonnees_contact">
                        <motion.li
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: .8, ease: "easeOut", duration: .5 }}
                        >
                            <FontAwesomeIcon icon={faKitchenSet} />
                            <span>Cuisine équipée</span>
                        </motion.li>
                        <motion.li
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: .8, ease: "easeOut", duration: .5 }}
                        >
                            <FontAwesomeIcon icon={faUtensils} />
                            <span>Salle à manger, salon</span>
                        </motion.li>
                        <motion.li
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: .8, ease: "easeOut", duration: .5 }}
                        >
                            <FontAwesomeIcon icon={faBacon} />
                            <span>Terrasse avec barbecue, plancha</span>
                        </motion.li>
                        <motion.li
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: .8, ease: "easeOut", duration: .5 }}
                        >
                            <FontAwesomeIcon icon={faCircleDot} />
                            <span>Billard</span>
                        </motion.li>
                        <motion.li
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: .8, ease: "easeOut", duration: .5 }}
                        >
                            <FontAwesomeIcon icon={faCircle} />
                            <span>Terrain de pétanque</span>
                        </motion.li>
                        <motion.li
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: .8, ease: "easeOut", duration: .5 }}
                        >
                            <FontAwesomeIcon icon={faHotTubPerson} />
                            <span>Jacuzzi, hammam</span>
                        </motion.li>
                        <motion.li
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: .8, ease: "easeOut", duration: .5 }}
                        >
                            <FontAwesomeIcon icon={faDisplay} />
                            <span>Écrans plats dans salon et chambres</span>
                        </motion.li>
                        <motion.li
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: .8, ease: "easeOut", duration: .5 }}
                        >
                            <FontAwesomeIcon icon={faWifi} />
                            <span>Internet avec Starlink</span>
                        </motion.li>
                        <motion.li
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: .8, ease: "easeOut", duration: .5 }}
                        >
                            <FontAwesomeIcon icon={faBed} />
                            <span>7 chambres</span>
                        </motion.li>
                        <motion.li
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: .8, ease: "easeOut", duration: .5 }}
                        >
                            <FontAwesomeIcon icon={faBath} />
                            <span>7 Salles de bain</span>
                        </motion.li>
                        <motion.li
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: .8, ease: "easeOut", duration: .5 }}
                        >
                            <FontAwesomeIcon icon={faPersonSkiing} />
                            <span>Local à ski</span>
                        </motion.li>
                    </ul>
                </div>

                <div className="width60 images">
                    <div className="width45 two_images">
                        <div className="image">
                            <motion.div
                                initial="hidden"
                                animate={controls}
                                variants={fadeIn}
                                transition={{ delay: .8 }}
                            >
                                <StaticImage src="../images/chalet/1.jpg" alt="terrasse_barbecue_plancha_chalet_standing_motagne_les_saisies_alpes_savoie" />
                            </motion.div>
                            <motion.div
                                className="first_background"
                                initial="hidden"
                                animate={controls}
                                variants={backgroundToBottom}
                                transition={{ delay: .6, ease: "easeInOut", duration: .8 }}
                            />
                        </div>

                        <div className="image">
                            <motion.div
                                initial="hidden"
                                animate={controls}
                                variants={fadeIn}
                                transition={{ delay: .8 }}
                            >
                                <StaticImage src="../images/88705b42f10.jpg" alt="hammam_chalet_luxe_standing_motagne_les_saisies_alpes_savoie" />
                            </motion.div>
                            <motion.div
                                className="first_background"
                                initial="hidden"
                                animate={controls}
                                variants={backgroundToTop}
                                transition={{ delay: .6, ease: "easeInOut", duration: .8 }}
                            />
                        </div>
                    </div>
                    <div className="width45 two_images">
                        <div className="image">
                            <motion.div
                                initial="hidden"
                                animate={controls}
                                variants={fadeIn}
                                transition={{ delay: .8 }}
                            >
                                <StaticImage src="../images/chalet/spa_jacuzzi_détente_chalet_standing_motagne_les_saisies_alpes_savoie_espace_diamant.jpg" alt="spa_jacuzzi_détente_chalet_standing_motagne_les_saisies_alpes_savoie_espace_diamant" />
                            </motion.div>
                            <motion.div
                                className="first_background"
                                initial="hidden"
                                animate={controls}
                                variants={backgroundToTop}
                                transition={{ delay: .6, ease: "easeInOut", duration: .8 }}
                            />
                        </div>
                        
                        <div className="image">
                            <motion.div
                                initial="hidden"
                                animate={controls}
                                variants={fadeIn}
                                transition={{ delay: .8 }}
                            >
                                <StaticImage src="../images/ef27f8b9f10.jpg" alt="local_ski_chalet_standing_motagne_les_saisies_alpes_savoie" />
                            </motion.div>
                            <motion.div
                                className="first_background"
                                initial="hidden"
                                animate={controls}
                                variants={backgroundToBottom}
                                transition={{ delay: .6, ease: "easeInOut", duration: .8 }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}