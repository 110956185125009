import React, { useEffect } from "react"
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { StaticImage } from "gatsby-plugin-image"
import scrollTo from 'gatsby-plugin-smoothscroll'
import "./intersection.scss"

export default function Intersection() {

    const controls = useAnimation();
    const { ref, inView } = useInView({});

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
        if (!inView) {
            controls.start('hidden');
        }
    }, [controls, inView]);

    const fadeIn = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    }

    return (
        <section className="intersection">
            <StaticImage
                src="../images/chalet/Panorama_Beaufortain.jpg"
                alt=""
                className="background_static_fixed"
                placeholder="blurred"
            />
            <div className="text_intersection">
                <div className="text-slider" ref={ref}>
                    <motion.p
                        className="before_title_white"
                        initial="hidden"
                        animate={controls}
                        variants={fadeIn}
                        transition={{
                            ease: "easeOut",
                            duration: .5,
                            delay: .3
                        }}
                    >
                        Une vue à couper le souffle
                    </motion.p>
                    <motion.h1
                        initial="hidden"
                        animate={controls}
                        variants={fadeIn}
                        transition={{
                            ease: "easeOut",
                            duration: .5,
                            delay: .4
                        }}
                    >
                        Venez contempler les magnifiques paysages de la Savoie
                    </motion.h1>
                    <motion.button
                        className="button_animate_t"
                        onClick={() => scrollTo("#contact")}
                        initial="hidden"
                        animate={controls}
                        variants={fadeIn}
                        transition={{
                            ease: "easeOut",
                            duration: .5,
                            delay: .5
                        }}
                    >
                        <span>Réserver</span>
                    </motion.button>
                </div>
            </div>
        </section>
    )
}