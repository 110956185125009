import React, { useEffect, useState } from 'react'
import './contact.scss'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';

export default function Contact() {

    const [formState, setFormState] = useState({
        name: "",
        email: "",
        tel: "",
        objet: "",
        dateDu: "",
        dateAu: "",
        message: ""
    })

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const handleChange = e => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
            [e.target.email]: e.target.value,
            [e.target.tel]: e.target.value,
            [e.target.objet]: e.target.value,
            [e.target.dateDu]: e.target.value,
            [e.target.dateAu]: e.target.value,
            [e.target.message]: e.target.value
        })
    }

    const handleSubmit = e => {

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...formState })
        })
            .then(() => alert("Votre message a bien été envoyé."))
            .catch(error => alert(error));

        e.preventDefault();
    }

    const controls = useAnimation();
    const { ref, inView } = useInView({});

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
        if (!inView) {
            controls.start('hidden');
        }
    }, [controls, inView]);

    const fadeIn = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    }

    return (
        <section id="contact" ref={ref}>
            <motion.div
                className="maps"
                initial="hidden"
                animate={controls}
                variants={fadeIn}
                transition={{ delay: .6, ease: "easeOut", duration: .5 }}
            >
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2784.4280985381683!2d6.512466515760011!3d45.74256292284658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478be79423deabf3%3A0x9c750b59ac714216!2sChalet%20Vers%20les%20Plans%3A%20Location%20vacances%20de%20groupe%20luxe%20h%C3%A9bergement%20familial%20ski%20s%C3%A9jour%20%C3%A0%20la%20montagne%20jacuzzi%20spa%20hammam%20tout%20confort%20LES%20SAISIES%20SAVOIE%20ESPACE%20DIAMANT%2073!5e0!3m2!1sfr!2sfr!4v1655038315573!5m2!1sfr!2sfr"
                    title="Google Maps Menuiserie du Beaufortain"
                    width="100%"
                    height="500"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    aria-hidden="false"
                />
            </motion.div>

            <div className="container contact_form">
                <div className="coo_contact width50">
                    <motion.p
                        className="before_title"
                        initial="hidden"
                        animate={controls}
                        variants={fadeIn}
                        transition={{ delay: .3, ease: "easeOut", duration: .5 }}
                    >
                        Coordonnées
                    </motion.p>
                    <motion.h1
                        initial="hidden"
                        animate={controls}
                        variants={fadeIn}
                        transition={{ delay: .6, ease: "easeOut", duration: .5 }}
                    >
                        Contact
                    </motion.h1>
                    <ul className="coordonnees_contact">
                        <motion.li
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: .8, ease: "easeOut", duration: .5 }}
                        >
                            <a href="tel:+33676293739">
                                <span className="nom">Téléphone</span>
                                <span className="coordonnee">06 76 29 37 39</span>
                            </a>
                        </motion.li>
                        <motion.li
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: 1, ease: "easeOut", duration: .5 }}
                        >
                            <a href="mailto:verslesplans@outlook.fr">
                                <span className="nom">Email</span>
                                <span className="coordonnee">verslesplans@outlook.fr</span>
                            </a>
                        </motion.li>
                        <motion.li
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: 1.1, ease: "easeOut", duration: .5 }}
                        >
                            <a href="https://goo.gl/maps/xiJVuojwNb3RYUQ48" target="_blank" rel="noreferrer">
                                <span className="nom">Adresse</span>
                                <span className="coordonnee">681 route du Reban - 73270 Villard-sur-Doron</span>
                            </a>
                        </motion.li>
                    </ul>
                </div>

                <div className="width50">
                    <div className="formulaire_contact">
                        <motion.p
                            className="before_title"
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: .4, ease: "easeOut", duration: .5 }}
                        >
                            Réservation
                        </motion.p>
                        <motion.h1
                            initial="hidden"
                            animate={controls}
                            variants={fadeIn}
                            transition={{ delay: .7, ease: "easeOut", duration: .5 }}>
                            Formulaire
                        </motion.h1>
                        <form id="id_form_contact" className="form_contact" name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" onSubmit={handleSubmit}>
                            <input
                                type="hidden"
                                name="form-name"
                                value="formulaire"
                            />
                            <motion.div
                                className="input_contact"
                                initial="hidden"
                                animate={controls}
                                variants={fadeIn}
                                transition={{ delay: .8, ease: "easeOut", duration: .5 }}
                            >
                                <label>
                                    Nom et Prénom *
                                    <input
                                        id="name"
                                        type="text"
                                        name="name"
                                        onChange={handleChange}
                                        value={formState.name}
                                        required
                                    />
                                </label>
                            </motion.div>
                            <motion.div
                                className="input_contact"
                                initial="hidden"
                                animate={controls}
                                variants={fadeIn}
                                transition={{ delay: .9, ease: "easeOut", duration: .5 }}
                            >
                                <label>
                                    Email *
                                    <input
                                        id="email"
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        value={formState.email}
                                        required
                                    />
                                </label>
                            </motion.div>
                            <motion.div
                                className="input_contact"
                                initial="hidden"
                                animate={controls}
                                variants={fadeIn}
                                transition={{ delay: 1, ease: "easeOut", duration: .5 }}
                            >
                                <label>
                                    Téléphone
                                    <input
                                        id="tel"
                                        type="tel"
                                        name="tel"
                                        onChange={handleChange}
                                        value={formState.tel}
                                    />
                                </label>
                            </motion.div>
                            <motion.div
                                className="input_contact"
                                initial="hidden"
                                animate={controls}
                                variants={fadeIn}
                                transition={{ delay: 1.1, ease: "easeOut", duration: .5 }}
                            >
                                <label>
                                    Objet
                                    <input
                                        id="objet"
                                        type="objet"
                                        name="objet"
                                        onChange={handleChange}
                                        value={formState.objet}
                                    />
                                </label>
                            </motion.div>
                            <motion.div
                                className="input_contact"
                                initial="hidden"
                                animate={controls}
                                variants={fadeIn}
                                transition={{ delay: 1.2, ease: "easeOut", duration: .5 }}
                            >
                                <label>
                                    Du
                                    <input
                                        id="dateDu"
                                        type="date"
                                        name="dateDu"
                                        onChange={handleChange}
                                        value={formState.dateDu}
                                    />
                                </label>
                            </motion.div>
                            <motion.div
                                className="input_contact"
                                initial="hidden"
                                animate={controls}
                                variants={fadeIn}
                                transition={{ delay: 1.2, ease: "easeOut", duration: .5 }}
                            >
                                <label>
                                    Au
                                    <input
                                        id="dateAu"
                                        type="date"
                                        name="dateAu"
                                        onChange={handleChange}
                                        value={formState.dateAu}
                                    />
                                </label>
                            </motion.div>
                            <motion.div
                                className="textarea_contact"
                                initial="hidden"
                                animate={controls}
                                variants={fadeIn}
                                transition={{ delay: 1.4, ease: "easeOut", duration: .5 }}
                            >
                                <label>
                                    Message *
                                    <textarea
                                        className="message_contact"
                                        name="message"
                                        onChange={handleChange}
                                        required>
                                    </textarea>
                                </label>
                            </motion.div>
                            <motion.button
                                type="submit"
                                className="button_animate"
                                initial="hidden"
                                animate={controls}
                                variants={fadeIn}
                                transition={{ delay: 1.5, ease: "easeOut", duration: .5 }}
                            >
                                <span className="texte_lien_span">
                                    Envoyer le message
                                </span>
                            </motion.button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}