import React, { useEffect } from 'react'
import { AnimateSharedLayout, motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { graphql, useStaticQuery } from 'gatsby';
import GalleryImages from './gallery-images';
import "./gallery.scss"


const PageGallery = () => {

    const dataImages = useStaticQuery(graphql`
    {
        allGalleryJson(sort: {fields: idImg, order: ASC}) {
          nodes {
            id
            img {
              childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED,
                    quality: 100
                )
              }
            }
            altImg
          }
        }
      }
    `)

    const controls = useAnimation();
    const { ref, inView } = useInView({});

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
        if (!inView) {
            controls.start('hidden');
        }
    }, [controls, inView]);

    const fadeIn = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    }

    return (
        <section id="photos" ref={ref}>
            <div className="text_gallery">
                <motion.p
                    className="before_title"
                    initial="hidden"
                    animate={controls}
                    variants={fadeIn}
                    transition={{ delay: .3, ease: "easeOut", duration: .5 }}
                >
                    Galerie
                </motion.p>
                <motion.h1
                    initial="hidden"
                    animate={controls}
                    variants={fadeIn}
                    transition={{ delay: .6, ease: "easeOut", duration: .5 }}>
                    Photos
                </motion.h1>
            </div>
            <div className="images">
                <AnimateSharedLayout type="crossfade">
                    {dataImages.allGalleryJson.nodes.map((doc) => (
                        <GalleryImages
                            doc={doc}
                            key={doc.id}
                            fadeIn={fadeIn}
                        />
                    ))}
                </AnimateSharedLayout>
            </div>
        </section>
    )
}

export default PageGallery