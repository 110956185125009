import React, { useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { fadeIn, toTop, toBottom, backgroundToTop, backgroundToBottom } from './functions';
import scrollTo from 'gatsby-plugin-smoothscroll'
import "./section-1.scss"

export default function Section1() {

    const controls = useAnimation();
    const { ref, inView } = useInView({});

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
        if (!inView) {
            controls.start('hidden');
        }
    }, [controls, inView]);

    return (
        <div className="about container" ref={ref}>
            <div className="top_block">
                <motion.p
                    initial="hidden"
                    animate={controls}
                    variants={fadeIn}
                    transition={{ delay: .3, ease: "easeOut", duration: .5 }}
                    className="before_title"
                >
                    Le Chalet
                </motion.p>
                <motion.h1
                    initial="hidden"
                    animate={controls}
                    variants={fadeIn}
                    transition={{ delay: .4, ease: "easeOut", duration: .5 }}
                >
                    Vers les Plans
                </motion.h1>
                <motion.p
                    className="text"
                    initial="hidden"
                    animate={controls}
                    variants={fadeIn}
                    transition={{ delay: .5, ease: "easeOut", duration: .5 }}
                >
                    Situé à 10min de la station Les Saisies, ce chalet de luxe spacieux peut accueillir jusqu'à 15 personnes. 192 kms de pistes vous attendent, retour au chalet possible à ski en hors-pistes. Navette gratuite à 100 m du chalet.<br />
                </motion.p>
                <motion.p
                    className="text"
                    initial="hidden"
                    animate={controls}
                    variants={fadeIn}
                    transition={{ delay: .5, ease: "easeOut", duration: .5 }}
                >
                    A votre disposition, un spa 5 places et un hammam, Ski room avec sèche chaussures et armoire chauffante.<br />
                    <a className="link_animate" onClick={() => scrollTo("#equipements")}>
                        <span className="texte_lien_span">
                            Voir la liste de tous les équipements
                        </span>
                    </a>
                </motion.p>
                <motion.p
                    className="text"
                    initial="hidden"
                    animate={controls}
                    variants={fadeIn}
                    transition={{ delay: .5, ease: "easeOut", duration: .5 }}
                >
                    Ensoleillé du matin au soir, ce chalet dispose d'une terrasse semi couverte de 50m² avec vue sur les montagnes et du soleil levant.
                </motion.p>
                <motion.p
                    className="text"
                    initial="hidden"
                    animate={controls}
                    variants={fadeIn}
                    transition={{ delay: .5, ease: "easeOut", duration: .5 }}
                >
                    Activités estivales nombreuses aux Saisies à 2 kms du chalet : randonnées pédestres balisées accessibles à tous, promenades à cheval, tennis, circuit VTT avec navettes, parapente, Mountain twister. Piscine à 5 kms et plan d'eau à 9 kms.<br />
                </motion.p>
                <motion.p
                    className="text"
                    initial="hidden"
                    animate={controls}
                    variants={fadeIn}
                    transition={{ delay: .5, ease: "easeOut", duration: .5 }}
                >
                    A votre arrivée, les lits seront faits et des serviettes de toilette à votre disposition.
                </motion.p>
            </div>
            <div className="bottom_block">
                <motion.div
                    className="image width30"
                    initial="hidden"
                    animate={controls}
                    variants={toTop}
                    transition={{ delay: .6, ease: "easeOut", duration: .6 }}
                >
                    <motion.div
                        initial="hidden"
                        animate={controls}
                        variants={fadeIn}
                        transition={{ delay: .8 }}
                    >
                        <StaticImage src="../images/chalet/3.jpg" alt="chalet_standing_montagne_les_saisies_alpes_savoie_espace_diamant_luxe" />
                    </motion.div>
                    <motion.div
                        className="first_background"
                        initial="hidden"
                        animate={controls}
                        variants={backgroundToTop}
                        transition={{ delay: .6, ease: "easeInOut", duration: .8 }}
                    />
                </motion.div>

                <motion.div
                    className="image width30"
                    initial="hidden"
                    animate={controls}
                    variants={toBottom}
                    transition={{ delay: .6, ease: "easeOut", duration: .6 }}
                >
                    <motion.div
                        initial="hidden"
                        animate={controls}
                        variants={fadeIn}
                        transition={{ delay: .8 }}
                    >
                        <StaticImage src="../images/chalet/mont_blanc_chalet_standing_montagne_les_saisies_alpes_savoie_espace_diamant_luxe.jpg" alt="mont_blanc_chalet_standing_montagne_les_saisies_alpes_savoie_espace_diamant_luxe" />
                    </motion.div>
                    <motion.div
                        className="first_background"
                        initial="hidden"
                        animate={controls}
                        variants={backgroundToBottom}
                        transition={{ delay: .6, ease: "easeInOut", duration: .8 }}
                    />
                </motion.div>

                <motion.div
                    className="image width30"
                    initial="hidden"
                    animate={controls}
                    variants={toTop}
                    transition={{ delay: .6, ease: "easeOut", duration: .6 }}
                >
                    <motion.div
                        initial="hidden"
                        animate={controls}
                        variants={fadeIn}
                        transition={{ delay: .8 }}
                    >
                        <StaticImage src="../images/chalet/billard_chalet_motagne_les_saisies.jpg" alt="billard_chalet_motagne_les_saisies" />
                    </motion.div>
                    <motion.div
                        className="first_background"
                        initial="hidden"
                        animate={controls}
                        variants={backgroundToTop}
                        transition={{ delay: .6, ease: "easeInOut", duration: .8 }}
                    />
                </motion.div>
            </div>
        </div>
    )
}