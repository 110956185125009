import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo.js'
import Contact from '../components/contact'
import SliderAccueil from '../components/slider-accueil'
import Section1 from '../components/section-1'
import "./index.scss"
import Intersection from '../components/intersection'
import PageGallery from '../components/gallery'
import Equipements from '../components/equipements'

export default function Index() {

  return (
    <div id="accueil">
      <Layout>
        <Seo title="Accueil" />

        <div className="section_1">
          <SliderAccueil />
        </div>

        <section id="le-chalet">
          <Section1 />
        </section>

        <Intersection />

        <Equipements />

        <PageGallery />

        <Contact />
      </Layout>
    </div>
  )
}