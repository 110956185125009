import React, { useEffect } from "react"
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { StaticImage } from "gatsby-plugin-image"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import './slider-accueil.scss'

export default function SliderAccueil() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false,
        arrows: false,
        fade: true
    }

    const controls = useAnimation();
    const { ref, inView } = useInView({});

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
        if (!inView) {
            controls.start('hidden');
        }
    }, [controls, inView]);

    const fadeIn = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    }

    return (
        <div className="slider_accueil">
            <Slider {...settings}>
                <div className="slide">
                    <StaticImage
                        src="../images/chalet/2.jpg"
                        alt=""
                        placeholder="blurred"
                    />
                </div>
                <div className="slide">
                    <StaticImage
                        src="../images/chalet/P1050159.jpg"
                        alt=""
                        placeholder="blurred"
                    />
                </div>
                <div className="slide">
                    <StaticImage
                        src="../images/chalet/salon_cocooning_chalet_standing_motagne_les_saisies_alpes_savoie_espace_diamant_luxe.jpg"
                        alt=""
                        placeholder="blurred"
                    />
                </div>
                <div className="slide">
                    <StaticImage
                        src="../images/chalet/panorama_mont_blanc_chalet_standing_montagne_les_saisies_alpes_savoie_espace_diamant.jpg"
                        alt=""
                        placeholder="blurred"
                    />
                </div>
            </Slider>

            <div className="text_logo_slider">
                <div className="text-slider" ref={ref}>
                    <motion.p
                        className="before_title"
                        initial="hidden"
                        animate={controls}
                        variants={fadeIn}
                        transition={{
                            ease: "easeOut",
                            duration: .5,
                            delay: .3
                        }}
                    >
                        Chalet haut de gamme, SPA
                    </motion.p>
                    <motion.h1
                        initial="hidden"
                        animate={controls}
                        variants={fadeIn}
                        transition={{
                            ease: "easeOut",
                            duration: .5,
                            delay: .4
                        }}
                    >
                        Location de chalet haut de gamme
                    </motion.h1>
                    <motion.button
                        className="button_animate"
                        onClick={() => scrollTo("#le-chalet")}
                        initial="hidden"
                        animate={controls}
                        variants={fadeIn}
                        transition={{
                            ease: "easeOut",
                            duration: .5,
                            delay: .5
                        }}
                    >
                        <span>Découvrez le chalet</span>
                    </motion.button>
                </div>
                <motion.div
                    initial="hidden"
                    animate={controls}
                    variants={fadeIn}
                    transition={{
                        ease: "easeOut",
                        duration: .5,
                        delay: .6
                    }}
                    className="text_logo"
                    onClick={() => scrollTo("#le-chalet")}
                    style={{ cursor: "pointer" }}
                >
                    VP
                </motion.div>
            </div>
        </div>
    )
}